/* eslint-disable no-nested-ternary */
import kebabCase from 'lodash/kebabCase';
import type { ReactElement } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { generateTestId } from '../../lib/test-helpers';
import { colors as legacyColors } from '../../theme';
import { cn } from '../../v2';
import type { HexColor, TextColors, TextWeights } from '../text';
import { textColors } from '../text';
import { Tooltip } from '../tooltip';

interface Option {
  label?: string;
  value: string;
  icon?: React.ReactNode;
  activeIcon?: React.ReactNode;
  testId?: string;
  tooltip?: string;
  disabled?: boolean;
}

type SegmentedControlVariant = 'regular' | 'thin';
export interface SegmentedControlV2Props {
  options: Option[];
  value?: string;
  onChange: (value: string) => unknown;
  fluid?: boolean;
  style?: React.CSSProperties;
  fontWeight?: TextWeights;
  inactiveFontWeight?: TextWeights;
  activeColor?: TextColors | HexColor;
  inactiveColor?: TextColors | HexColor;
  variant?: SegmentedControlVariant;
  testId?: string;
  containerClassname?: string;
}

export function SegmentedControlV2(props: SegmentedControlV2Props): JSX.Element {
  const {
    options,
    value,
    onChange,
    fluid = false,
    style = {},
    fontWeight = 600,
    inactiveFontWeight = 400,
    activeColor = 'active',
    variant = 'regular',
    testId: propTestId,
    containerClassname,
  } = props;

  const { inactiveColor = '#606F85' } = props;

  const containerClassnames = cn(
    'flex border border-solid border-[#dbdbdb]',
    {
      fluid,
    },
    containerClassname,
  );

  const getColor = (isActive: boolean) => {
    return isActive ? textColors[activeColor] ?? activeColor : textColors[inactiveColor] ?? inactiveColor;
  };

  const items = options.map((option) => {
    const isActive = value === option.value;
    const color = getColor(isActive);

    const className = cn('border-[#dbdbdb]', {
      isActive,
      fluid,
    });
    const testId = option.testId ?? generateTestId(kebabCase(option.value), 'segmented-option');
    const icon = isActive && option.activeIcon ? option.activeIcon : option.icon;

    const getLabel = (): JSX.Element => {
      if (!icon && !option.label) {
        // This shouldn't happen
        return <></>;
      }
      if (icon && !option.label) {
        return (
          <div>
            {React.cloneElement(icon as ReactElement, {
              className: `text-[${color}]`,
            })}
          </div>
        );
      }

      if (!icon && option.label) {
        return (
          <p
            style={{
              color,
            }}
            className={`font-[${option.disabled || !isActive ? inactiveFontWeight : fontWeight}] text-center`}
          >
            {option.label}
          </p>
        );
      }

      // Icon and Label exist
      return (
        <div className="flex flex-row items-center justify-center gap-0.5">
          {React.cloneElement(icon as ReactElement, {
            className: `text-[${color}]`,
          })}
          <p
            style={{
              color,
            }}
            className={`font-[${option.disabled || !isActive ? inactiveFontWeight : fontWeight}] text-center`}
          >
            {option.label}
          </p>
        </div>
      );
    };

    const optionComponent = (
      <StyledOption
        key={option.value}
        data-testid={testId}
        className={className}
        onClick={() => (option.disabled ? undefined : onChange(option.value))}
        role="checkbox"
        aria-checked={isActive}
        tabIndex={0}
        onKeyDown={(e) => {
          if (option.disabled) return;
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onChange(option.value);
          }
        }}
        variant={variant}
        hasIcon={!!icon}
        isActive={isActive}
        disabled={option.disabled}
      >
        {getLabel()}
      </StyledOption>
    );

    return option.tooltip ? (
      <Tooltip key={option.value} message={option.tooltip} positionTip="bottom" maxWidth={350} disableKeyboardFocus>
        {optionComponent}
      </Tooltip>
    ) : (
      optionComponent
    );
  });

  return (
    <StyledFlexbox className={containerClassnames} style={style} variant={variant} data-test-id={propTestId}>
      {items}
    </StyledFlexbox>
  );
}

const StyledFlexbox = styled.div(
  ({ variant }: { variant: SegmentedControlVariant }) => `
  border-radius: ${variant === 'thin' ? 6 : 4}px;
  box-sizing: border-box;
  width: max-content;
  height: max-content;
  background-color: #F4F5F6;
  border: 1px solid #DEE2E7;
  padding: 4px;
  &.fluid {
    width: 100%;
  }
`,
);

const StyledOption = styled.div(
  ({
    variant,
    hasIcon,
    isActive = false,
    disabled = false,
  }: {
    variant: SegmentedControlVariant;
    hasIcon: boolean;
    isActive?: boolean;
    disabled?: boolean;
  }) => `
  padding: ${hasIcon ? `5px 16px` : variant === 'thin' ? '0px 16px 1px 16px' : '4px 16px 4px'};
  ${!disabled ? 'cursor: pointer' : 'cursor: not-allowed'};
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    isActive
      ? `
    background-color: #0957C380;
    border: 1px solid #0957C3;`
      : ''
  }
  border-radius: 4px;

  &.fluid {
    flex: 1;
  }

  &.isActive {
    background-color: ${legacyColors.primary_xxxl};
  }
`,
);
